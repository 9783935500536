import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import { useParams, useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { openDB } from "idb";
import { usePostHog, useFeatureFlagEnabled, useFeatureFlagPayload } from "posthog-js/react";
import axios from "axios";
import { z } from "zod";

import { AppContext } from "../UtilityFunctions/AppContext.js";
import s from "../../styleModules/setupStyles.module.css";
import { useAxiosLimited } from "../UtilityFunctions/axiosRetry.js";
import useChatMessages from "../UtilityFunctions/useChatMessages.js";
import { ResumeModal } from "../SecondaryComponents/ResumeModal.js";
import { handleGeneration } from "./SetupElements/handleGeneration.js";
import { getRandomArrayElement } from "../UtilityFunctions/getRandomArrayElement.js";
import { BrowserSupportModal, MobileModal, AssignmentModal, OngoingModal, createNewDynamicAssingnment } from "./SetupElements/SetupModals.js";
import { getRandomInt } from "../UtilityFunctions/getRandomInt.js";
import { learning_points_array } from "../LearningPoints.js";
import { process_preliminaries, process_opening, process_closing, process_SPIN, process_BANT } from "../ProcessList.js";
import SetupPage from "./SetupPage.js";
import Competencies from "./Competencies.js";
import $ from "jquery";
import { TryCatch } from "@sentry/react";

const url = process.env.REACT_APP_BACKEND_STATIC_URL;
const ltiBackend = process.env.REACT_APP_LTI_BACKEND_URL;
const ltiKey = "1455ded6-62b5-410d-bebd-bc1645c662ab"; // process.env.REACT_APP_LTIAAS_KEY;
const deeplinkSchema = z.object({
	// personality: z.string().optional(),

	// discovery
	// prospect_company_description: z.string().optional(),
	// prospect_company_name: z.string().optional(),
	// sales_rep_company_description: z.string().optional(),
	// sales_rep_company_name: z.string().optional(),
	// buyer_title: z.string().optional(),
	// call_notes: z.string().optional(),

	// presentation
	// audience_role: z.string().optional(),
	// objective: z.string().optional(),
	// target_time: z.number().optional(),
	// question_list: z.array(z.unknown()).optional(),
	// user_role: z.string().optional(),

	// interview
	// short_summary: z.string().optional(),
	// skills_required: z.array(z.string()).optional(),

	setup_input: z.string(),
	setup_name: z.string(),
	setup_type: z.string(),
	assignment_type: z.string(),
	simulation_id: z.number()
});
const defaultSetupData = {
	// Common properties
	product: "",
	meeting_details: "",
	sales_rep_company_name: "",
	sales_rep_company_description: "",
	prospect_company_name: "",
	prospect_company_description: "",
	buyer_title: "",
	question_list: [],

	// Interview
	job_title: "",
	job_description: "",
	short_summary: "",
	job_function: "",
	experience_level: "",
	industry: "",
	main_responsibilities: [],
	skills_required: [],

	// Discovery

	// Discovery Spin

	// Closing

	// Retail
	additional_details: "",

	// Presentation
	topic: "",
	presentation_details: "",
	target_time: "",
	audience_role: "",
	objective: "",

	// Intro
	intro_event: "",
	intro_details: "",
	audience_role: "",
	user_role: "",

	// Freestyle
	convo_type: "",
	user_details: "",
	audience_details: "",
	user_role: "",
	audience_role: "",

	// Patient
	visit_type: "",
	patient_summary: "",
	patient_bio: "",
	patient_medical: "",

	// Pitch & Business Pitch
	pitch_topic: "",
	pitch_details: "",

	// Reflection
	// experience_details:
	// 	"- The co-op took place at FutureTech, headquartered in Toronto, in their software development department.\n- I participated in the co-op during the Fall semester.\n- It lasted six months.\n- The co-op entailed working as a junior software developer, where I contributed to project teams developing new applications, engaged in code reviews, and participated in daily stand-ups and agile sprint meetings.",
	// phase: "after",
	experience_type: "",
	experience_type_description: "",
	experience_details: "",
	phase: "",
	// competency_list: Competencies.filter((competency) => ["Communication", "Critical Thinking", "Lifelong Learning"].includes(competency.name))
	competency_list: []
};

const calculateTerm = () => {
	const date = new Date();
	const month = date.getMonth();
	// Updated to reflect the correct seasonal months
	return month < 2 ? "Winter" : month < 5 ? "Spring" : month < 8 ? "Summer" : "Fall";
};

const defaultAssignmentData = {
	assignment_type: "",
	assignment_name: "",
	due_date: "",
	submission_instructions: "",
	assignment_instructions: "",
	content_weight: 40,
	delivery_weight: 40,
	completion_weight: 20,
	other_weight: 0,
	course_options: [],
	term_options: [calculateTerm()],
	passing_grade: 50,
	term: calculateTerm()
};

const questionTypeOptions = [
	{
		value: "Resume",
		label: "Resume",
		text: "",
		framework: [
			"Relevance: Does the candidate relate their resume details to the role?",
			"Depth: Does the candidate provide detailed examples from their resume?"
		]
	},
	{
		value: "Behavioral",
		label: "Behavioral",
		text: "",
		framework: [
			"Situation: Does the candidate clearly describe a real situation from their past experience?",
			"Task: Do they accurately define their task or responsibility within this situation?",
			"Action: Did they articulate their specific actions in response to this situation/task?",
			"Result: Did they describe the outcome of their actions, and was the outcome positive or constructive?"
		]
	},
	{
		value: "Situational",
		label: "Situational",
		text: "",
		framework: [
			"Scenario: Does the candidate grasp the hypothetical scenario and its complexities?",
			"Strategy: Do they outline a clear, realistic strategy or approach for handling the scenario?",
			"Impact: Do they consider the potential impacts or outcomes of their proposed approach?"
		]
	},
	{
		value: "Cultural Fit",
		label: "Cultural Fit",
		text: "",
		framework: [
			"Values: Do the candidate's values align with the company's?",
			"Adaptability: Can they adapt to different work cultures and dynamics?",
			"Compatibility: Do they seem likely to mesh well with diverse teams?"
		]
	},
	{
		value: "Technical",
		label: "Technical",
		text: "",
		framework: [
			"Understanding: Does the candidate display a deep grasp of required technical concepts?",
			"Problem-solving: Can they apply this knowledge to solve problems?",
			"Communication: Can they explain complex technical concepts clearly?"
		]
	},
	{
		value: "General",
		label: "General",
		text: "",
		framework: [
			"Relevance: Does the candidate relate their answer to the question?",
			"Depth: Does the candidate provide detailed examples to support their answer?"
		]
	}
];

async function initDB() {
	return openDB("simulation", 1, {
		upgrade(db) {
			db.createObjectStore("simulation_db");
		}
	});
}

export default function Setup({ setupType: propsetupType }) {
	const { axiosLimitedGet, axiosLimitedPost, axiosLimitedPut, axiosLimitedPatch, axiosLimitedDelete } = useAxiosLimited();
	const { availableAvatars, setAvailableAvatars, handleNavigate } = useContext(AppContext);
	const location = useLocation();
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();

	const posthog = usePostHog();

	const { updateChatMessages } = useChatMessages();

	const ActiveAvatarFlag = useFeatureFlagEnabled("active-avatars-flag");
	const ActiveAvatarPayload = useFeatureFlagPayload("active-avatars-flag");

	useEffect(() => {
		setAvailableAvatars(ActiveAvatarFlag ? ActiveAvatarPayload.Avatars : ["David", "Nina", "John", "Myra", "Random"]);
	}, [ActiveAvatarFlag, ActiveAvatarPayload, setAvailableAvatars]);

	// Add this useEffect hook
	useEffect(() => {
		// This function will be called when the component unmounts
		return () => {
			setResumeTemplateData({
				contact: {
					firstName: "",
					lastName: "",
					email: "",
					address: "",
					phone: "",
					linkedinURL: ""
				},
				summary: {
					summary: ""
				},
				experience: [
					{
						id: Date.now(),
						jobTitle: "",
						companyName: "",
						city: "",
						stateOrProv: "",
						startMonth: "",
						startYear: "",
						endMonth: "",
						endYear: "",
						roles: []
					}
				],
				education: [
					{
						id: Date.now(),
						school: "",
						degree: "",
						program: "",
						graduatingYear: ""
					}
				],
				extraCurricular: [
					{
						id: Date.now(),
						activity: "",
						orgName: "",
						startMonth: "",
						startYear: "",
						endMonth: "",
						endYear: "",
						tasks: []
					}
				],
				volunteer: [
					{
						id: Date.now(),
						activity: "",
						orgName: "",
						startMonth: "",
						startYear: "",
						endMonth: "",
						endYear: "",
						tasks: []
					}
				],
				skills: {
					technical: [],
					coreCompetencies: []
				}
			});

			setCoverLetterTemplateData({
				contact: {
					firstName: "",
					lastName: "",
					email: "",
					address: "",
					phone: "",
					hiring_manager_name: "",
					company: "",
					company_address: ""
				},
				intro: {
					intro: ""
				},
				body: {
					body: ""
				}
			});
		};
	}, []); // Empty dependency array

	// GLOBAL VARIABLES
	const { setupType, savedId, simId } = useParams();
	const setup_type = setupType ? setupType.toLowerCase() : propsetupType.toLowerCase();

	const typingRefs1 = useRef(null);

	const {
		userLanguage,
		clientType,
		setGPTStatus,
		adminAccess,
		setAdminAccess,
		localUser,
		resumeAssistAccess,
		tenantId,
		showSidebar,
		setShowSidebar,
		setSidebarContent,
		setSidebarContentView,
		sidebarContentView,
		setChatMessages,
		SetupList,
		setupData,
		setSetupData,
		setActiveSessionData,
		resumeData,
		setResumeData,
		coverLetterData,
		setCoverLetterData,
		setGptAssistData,
		gptAssistScore,
		setGptAssistScore,
		translateDictText,
		translateSingleText,
		setResumeTemplateData,
		setCoverLetterTemplateData
	} = useContext(AppContext);

	const [editAccess, setEditAccess] = useState(false);
	const [questionListViewAccess, setQuestionListViewAccess] = useState(false);

	// Memoize SetupList to prevent unnecessary updates
	const memoizedSetupList = useMemo(() => SetupList, [SetupList]);
	const [simulationForbidden, setSimulationForbidden] = useState(false);
	const [simulationNotFound, setSimulationNotFound] = useState(false);

	const audienceOptions = ActiveAvatarFlag ? ActiveAvatarPayload.Avatars : ["David", "Nina", "John", "Myra", "Random"];
	const [personalities, setPersonalities] = useState({});
	const DEFAULT_PERSONALITY_OPTIONS = [
		{ value: "Playful", label: "Playful" },
		{ value: "Cheeky", label: "Cheeky" },
		{ value: "Angry", label: "Angry" },
		{ value: "Reserved", label: "Reserved" }
	];
	const DEFAULT_PERSONALITY_OPTIONS_PATIENT_SIM = [
		{ value: "Elusive", label: "Elusive" },
		{ value: "Angry", label: "Angry" },
		{ value: "Playful", label: "Playful" },
		{ value: "Reserved", label: "Reserved" }
	];
	const [personalityOptions, setPersonalityOptions] = useState(DEFAULT_PERSONALITY_OPTIONS);
	const [personalityOptionsTranslated, setPersonalityOptionsTranslated] = useState(["Cheeky", "Playful", "Angry", "Reserved"]);

	const [randomAudience, setRandomAudience] = useState();
	const [randomPersonality, setRandomPersonality] = useState();

	const [lastActivity, setLastActivity] = useState(Date.now());
	const [saveAudio, setSaveAudio] = useState(true);
	const [captureOn, setCaptureOn] = useState(false);
	const [simDeleted, setSimDeleted] = useState(false);

	// TESTING STATES
	// const [generateStarted, setGenerateStarted] = useState(true);
	// const [showBackstory, setShowBackstory] = useState(true);

	// SIM STATES
	const [generateStarted, setGenerateStarted] = useState();
	const [showBackstory, setShowBackstory] = useState(false);
	const [ongoingSim, setOngoingSim] = useState(null);
	const [activeTab, setActiveTab] = useState(0);

	// GENERATE INPUT STATES
	const [assignmentData, setAssignmentData] = useState(defaultAssignmentData);
	const [newAssignmentType, setNewAssignmentType] = useState("");

	// GENERATE QUESTIONS
	const [isGenerating, setIsGenerating] = useState(false);

	// GENERATE ERROR STATES
	const [error1, setError1] = useState(false);
	const [error2, setError2] = useState(false);
	const [error3, setError3] = useState(false);

	const [videoOn, setVideoOn] = useState(true);
	const [showStartSimBtn, setShowStartSimBtn] = useState(false);

	// MEETING DATA
	const [backstory, setBackstory] = useState("");
	const [impressions, setImpressions] = useState("");
	const [callNotes, setCallNotes] = useState("");
	const [firstMessageName, setFirstMessageName] = useState("");
	const [firstMessageText, setFirstMessageText] = useState("");
	const [budget, setBudget] = useState(0);

	// MEETING CONTROLS
	const [showOngoingModal, setShowOngoingModal] = useState(false);
	const [showAssignmentModal, setShowAssignmentModal] = useState(false);
	const [showResumeModal, setShowResumeModal] = useState(false);
	const [assignmentDetails, setAssignmentDetails] = useState({});

	const formatTime = () => {
		const date = new Date();
		let hours = date.getHours();
		let minutes = date.getMinutes();
		const ampm = hours >= 12 ? "PM" : "AM";
		hours = hours % 12 || 12;
		minutes = minutes < 10 ? `0${minutes}` : minutes;
		return `${hours}:${minutes} ${ampm}`;
	};

	const shuffleArray = (array) => {
		for (let i = array.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1));
			[array[i], array[j]] = [array[j], array[i]];
		}
		return array;
	};

	const setDefaultSetupData = () => {
		// Use the setup_type to look up the sim in the SetupList array and set it to the setupData state
		const setup = memoizedSetupList.find((foundSetup) => foundSetup.setup_type === setup_type);
		setSetupData({ ...setup, ...defaultSetupData });
	};

	useEffect(() => {
		// This part handles changing the personality options based on setupData.setup_type
		// It's crucial to trigger this logic only when setupData.setup_type changes
		if (setupData?.setup_type === "patient") {
			setPersonalityOptions(DEFAULT_PERSONALITY_OPTIONS_PATIENT_SIM);
		} else {
			setPersonalityOptions(DEFAULT_PERSONALITY_OPTIONS);
		}
	}, [setupData?.setup_type]);

	// ... existing code ...
	useEffect(() => {
		// Common logic to handle mapping "Random" to "???"
		let updatedPersonalities = audienceOptions.reduce((acc, option) => {
			if (option === "Random") {
				acc[option] = "???";
			}
			return acc;
		}, {});

		// Ensure this logic runs only when needed based on your conditions
		if (!generateStarted) {
			// Shuffle the personality options to randomize their order
			const shuffledPersonalities = shuffleArray(personalityOptions.filter((option) => option.value !== "Random"));
			// Assign each shuffled personality to an audience option, ensuring one-to-one mapping, except "Random"
			audienceOptions.forEach((option, index) => {
				if (option !== "Random") {
					updatedPersonalities[option] = shuffledPersonalities[index % shuffledPersonalities.length].value;
				}
			});
		} else if (generateStarted && setupData.audience && setupData.audience.length > 0) {
			// Ensure setupData.personality is excluded from the random options for others
			const excludedOptions = shuffleArray(
				personalityOptions.filter((option) => option.value !== setupData.personality && option.value !== "Random")
			);
			let excludedIndex = 0; // To iterate through excludedOptions without duplication

			audienceOptions.forEach((option) => {
				if (option !== "Random") {
					if (option === setupData.audience[0]) {
						// Directly assign setupData.personality to the matched audienceOption
						updatedPersonalities[option] = setupData.personality;
					} else {
						// Assign a personality from the excluded options, ensuring no duplicates
						updatedPersonalities[option] = excludedOptions[excludedIndex++ % excludedOptions.length].value;
					}
				}
			});
		}

		setPersonalities(updatedPersonalities);
	}, [generateStarted, setupData?.audience, setupData?.personality, personalityOptions]);

	// useEffect(() => {
	// 	async function logAllData() {
	// 		// Open the database
	// 		const db = await openDB('simulation', 1);

	// 		// Start a read transaction on your object store
	// 		const tx = db.transaction('simulation_db', 'readonly');
	// 		const store = tx.objectStore('simulation_db');

	// 		// Use getAll to retrieve all records and getAllKeys to retrieve all keys from the store
	// 		const allKeys = await store.getAllKeys();
	// 		const allValues = await store.getAll();

	// 		// Combine keys and values into key-value pairs
	// 		const keyValuePairs = allKeys.map((key, index) => ({ key, value: allValues[index] }));

	// 		// Log the key-value pairs to the console
	// 		console.log(keyValuePairs);

	// 		// Close the transaction
	// 		await tx.done;
	// 	}

	//     logAllData().catch(err => console.error('Failed to log data from IndexedDB:', err));
	// }, []);

	useEffect(() => {
		if (location.pathname.includes("preview") && setup_type !== "intro") {
			handleNavigate("/preview-setup/intro");
		}

		setDefaultSetupData();

		setShowSidebar(false);
		setGptAssistData(null);
	}, [setup_type]);

	// useEffect(() => {
	// 	let interval = setInterval(async () => {
	// 		// Check if 5 minutes have passed since the last activity
	// 		if (Date.now() - lastActivity > 5 * 60 * 1000) {
	// 			// If so, stop the interval
	// 			clearInterval(interval);
	// 			interval = null;
	// 		} else {
	// 			try {
	// 				// Otherwise, continue checking the status
	// 				//const response = await axiosLimitedGet(`${url}/api/status/CheckGPT`, 1, {});
	// 				setGPTStatus(response.data);
	// 			} catch (error) {
	// 				console.log(url, error);
	// 				throw error;
	// 			}
	// 		}
	// 	}, 60000);

	// 	return () => clearInterval(interval);
	// }, [lastActivity]); // This effect depends on the last activity

	useEffect(() => {
		setBudget(getRandomInt(5, 1000));
	}, []);

	useEffect(() => {
		if (savedId) {
			// console.log("Setting data to null because savedId is present");
			setResumeData(null);
			setCoverLetterData(null);
			setGptAssistData(null);
		}
	}, [savedId]);
	const getLtik = () => {
		// console.log("getLtik");
		const ltik = searchParams.get("ltik");
		// console.log(ltik);
		if (!ltik) return false;
		return ltik;
	};
	const getLmsToken = () => {
		// console.log("getLmsToken");
		const lmsToken = searchParams.get("token");
		// console.log(lmsToken);
		if (!lmsToken) return false;
		return lmsToken;
	};
	const getLmsAssignmentType = () => {
		// console.log("getLtik");
		const ltik = searchParams.get("assignmentType");
		// console.log(ltik);
		if (!ltik) return false;
		return ltik;
	};
	useEffect(() => {
		const assignmentType = searchParams.get("assignmentType");
		// console.log("assignmentType", assignmentType);

		setNewAssignmentType(assignmentType);
		setAssignmentData({ ...assignmentData, assignment_type: assignmentType });

		// if (assignmentType) {
		// 	setAssignmentData({ ...assignmentData, assignment_type: assignmentType });
		// }
		// editAccess={editAccess}
		// 	updateEduData={updateEduData}
		// 	navigate={navigate}
		// 	localUser={localUser}
		// 	data={assignmentData}
		// 	setData={setAssignmentData}
		// 	setup_type={setup_type}
		// 	setShowAssignmentModal={setShowAssignmentModal}
		// 	newAssignmentType={newAssignmentType}
		// 	setCaptureOn={setCaptureOn}
	}, [searchParams]);

	async function summitDeeplink() {
		try {
			let data;
			if (newAssignmentType === "dynamic") {
				data = await createNewDynamicAssingnment(
					setup_type,
					setupData,
					localUser,
					{ assignment_instructions: "", due_date: "", submission_instructions: "", assignment_name: "" },
					newAssignmentType,
					axiosLimitedPost
				);
			}
			let simulation_id = setupData.simulation_id || data?.payload.simulation_id;
			// if simulation_id is not a number parseit to a number
			if (simulation_id && typeof simulation_id !== "number") {
				simulation_id = parseInt(simulation_id, 10);
			}
			// console.log("setupData", setupData);
			// console.log("data?.payload", data?.payload);
			// console.log("z inputs ", { ...setupData, ...data?.payload, setup_input: setupData.setup_input || "", simulation_id });
			const finalPayload = deeplinkSchema.parse({
				...setupData,
				...data?.payload,
				setup_input: setupData.setup_input || "",
				simulation_id,
				setup_name: setupData.setup_name,
				assignment_type: newAssignmentType
			});
			// console.log("summitDeeplink", finalPayload);

			// const form = await axios.post(ltiBackend + "/deeplink", finalPayload, {
			// 	headers: { Authorization: "Bearer " + getLtik() }
			// });

			// const items = {
			// 	type: "ltiResourceLink",
			// 	title: `${resource.setup_type} for ${resource.setup_name}`,

			// 	custom: resource,
			// 	lineitem: {
			// 		label: resource.setup_type,
			// 		scoreMaximum: 100,
			// 		gradesReleased: true
			// 	}
			// };

			const authenticationHeader = `LTIK-AUTH-V2 ${getLtik()}`;
			const headers = { Authorization: authenticationHeader };

			if (getLtik()) {
				const response = await axios.post(
					`${url}/lti/deep_link`,
					{ finalPayload, simulation_id, ltik: getLtik() },
					{
						headers
					}
				);

				console.log("data", response.data);
				console.log("form", response.data.form);

				document.body.insertAdjacentHTML("beforeend", response.data.form);

				$("body").append(response.data.form);
			}
		} catch (error) {
			if (error instanceof z.ZodError) {
				console.error("ZodError:", error.errors);
			} else {
				console.error(error);
			}
		}
	}

	// useEffect(() => {
	// // 	console.log("setupData", setupData);
	// }, [setupData]);

	useEffect(() => {
		// console.log("localUser", localUser);
		if (savedId && savedId !== "-1" && !savedId.startsWith("accId") && localUser) {
			console.log("Getting saved sim data...", userLanguage);
			axiosLimitedGet(`${url}/api/saved/simulation`, 1, { params: { simulation_id: savedId } })
				.then(async (response) => {
					// console.log("Saved sim data:", savedId, response.data);

					if (response.data.assignment_type !== undefined) {
						// console.log("Assignment data:", response.data);
						setAssignmentData({
							creator_instage_id: response.data.instage_id || "",
							assignment_type: response.data.assignment_type || "",
							assignment_name: response.data.assignment_name || "",
							due_date: response.data.due_date || "",
							submission_instructions: response.data.submission_instructions || "",
							assignment_instructions: response.data.assignment_instructions || "",
							content_weight:
								response.data.content_weight !== undefined ? response.data.content_weight : defaultAssignmentData.content_weight,
							delivery_weight:
								response.data.delivery_weight !== undefined ? response.data.delivery_weight : defaultAssignmentData.delivery_weight,
							completion_weight:
								response.data.completion_weight !== undefined
									? response.data.completion_weight
									: defaultAssignmentData.completion_weight,
							other_weight: response.data.other_weight !== undefined ? response.data.other_weight : defaultAssignmentData.other_weight,
							require_video_recording: response.data.require_video_recording || false,
							course_options: response.data.course_options || [],
							term_options: response.data.term_options || [],
							section_options: response.data.section_options || [],
							passing_grade:
								response.data.passing_grade !== undefined ? response.data.passing_grade : defaultAssignmentData.passing_grade
						});

						if (response.data.require_video_recording) {
							setCaptureOn(true);
						}

						initDB().then(async (db) => {
							// Retrieve the "hiddenInstructions" object
							const hiddenInstructions = (await db.get("simulation_db", "hiddenInstructions")) || {};
							const shouldShowModal = hiddenInstructions[savedId] !== false;
							setShowAssignmentModal(shouldShowModal);
						});
					} else {
						setAssignmentData(defaultAssignmentData);
					}

					const strTime = formatTime();

					let currentAudience;
					let currentPersonality;

					if (!response.data.audience || response.data.audience[0] === "Random") {
						currentAudience = [getRandomArrayElement(audienceOptions.filter((option) => option !== "Random"))];
						currentPersonality = getRandomArrayElement(personalityOptions).value;
						setRandomAudience(currentAudience);
						setRandomPersonality(currentPersonality);
					} else {
						currentAudience = response.data.audience;
						currentPersonality = response.data.personality;
					}

					const commonData = {
						instage_id: localUser.id,
						creator_instage_id: response.data.instage_id || "",
						simulation_id: savedId,
						current_time: strTime,
						audience: currentAudience,
						personality: currentPersonality
					};

					// console.log(response.data)
					if (response.data.setup_type) {
						// console.log("first message userLanguage", userLanguage);
						const data = { ...commonData, userLanguage };
						let apiPath = "";
						const setupDataUpdate = {};
						const commonUpdates = { setup_name: response.data.setup_name };
						const key_points =
							response.data.question_list?.map((question) => ({
								short: question.type,
								question: question.text,
								feedback: "",
								status: ""
							})) || [];

						const setupTypeConfig = {
							interview: {
								apiPath: "/api/interview/GetFirstMessage",
								dataUpdates: {
									job_title: response.data.job_title,
									job_description: response.data.job_description,
									short_summary: response.data.short_summary,
									job_function: response.data.job_function,
									experience_level: response.data.experience_level,
									industry: response.data.industry,
									main_responsibilities: response.data.main_responsibilities,
									skills_required: response.data.skills_required,
									question_list: response.data.job_question_list,
									keywords: response.data.keywords,
									...(localUser.resume_details && { resume_details: localUser.resume_details }),
									monsterUrl: response.data.monsterUrl || ""
								}
							},
							discovery: {
								apiPath: "/api/discovery/GetFirstMessage",
								dataUpdates: {
									product: response.data.setup_name,
									meeting_details: response.data.setup_input,
									call_notes: response.data.call_notes,
									buyer_title: response.data.buyer_title,
									backstory: response.data.prospect_company_description,
									company: response.data.sales_rep_company_name,
									company_description_short: response.data.sales_rep_company_description,
									prospect_company_name: response.data.prospect_company_name,
									prospect_company_description: response.data.prospect_company_description,
									sales_rep_company_name: response.data.sales_rep_company_name,
									sales_rep_company_description: response.data.sales_rep_company_description,
									key_points,
									learning_points: learning_points_array,
									process_points: [process_preliminaries, process_opening, ...process_BANT, process_closing]
								}
							},
							discovery_spin: {
								apiPath: "/api/discovery_spin/GetFirstMessage",
								dataUpdates: {
									product: response.data.setup_name,
									meeting_details: response.data.setup_input,
									call_notes: response.data.call_notes,
									buyer_title: response.data.buyer_title,
									company: response.data.sales_rep_company_name,
									company_description_short: response.data.sales_rep_company_description,
									prospect_company_name: response.data.prospect_company_name,
									prospect_company_description: response.data.prospect_company_description,
									sales_rep_company_name: response.data.sales_rep_company_name,
									sales_rep_company_description: response.data.sales_rep_company_description,
									buyer_situation_1: response.data.buyer_situation_1,
									buyer_situation_2: response.data.buyer_situation_2
								}
							},
							closing: {
								apiPath: "/api/closing/GetFirstMessage",
								dataUpdates: {
									product: response.data.setup_name,
									meeting_details: response.data.setup_input,
									company: response.data.sales_rep_company_name,
									company_description_short: response.data.sales_rep_company_description,
									prospect_company_name: response.data.prospect_company_name,
									prospect_company_description: response.data.prospect_company_description,
									sales_rep_company_name: response.data.sales_rep_company_name,
									sales_rep_company_description: response.data.sales_rep_company_description,
									buyer_title: response.data.buyer_title,
									buyer_need_1: response.data.buyer_need_1,
									buyer_need_2: response.data.buyer_need_2,
									buyer_objection_1: response.data.buyer_objection_1,
									buyer_objection_2: response.data.buyer_objection_2
								}
							},
							retail: {
								apiPath: "/api/retail/GetFirstMessage",
								dataUpdates: {
									product: response.data.setup_name,
									additional_details: response.data.setup_input,
									buyer_title: response.data.buyer_title,
									company: response.data.sales_rep_company_name,
									company_description_short: response.data.sales_rep_company_description,
									sales_rep_company_name: response.data.sales_rep_company_name,
									sales_rep_company_description: response.data.sales_rep_company_description,
									question_list: response.data.question_list
								}
							},
							presentation: {
								apiPath: "/api/presentation/GetFirstMessage",
								dataUpdates: {
									topic: response.data.setup_name,
									presentation_details: response.data.setup_input,
									target_time: response.data.target_time,
									audience_role: response.data.audience_role,
									objective: response.data.objective,
									question_list: response.data.question_list
								}
							},
							intro: {
								apiPath: "/api/intro/GetFirstMessage",
								dataUpdates: {
									intro_event: response.data.setup_name,
									intro_details: response.data.setup_input,
									audience_role: response.data.audience_role,
									user_role: response.data.user_role,
									question_list: response.data.question_list
								}
							},
							freestyle: {
								apiPath: "/api/freestyle/GetFirstMessage",
								dataUpdates: {
									convo_type: response.data.setup_name,
									user_details: response.data.user_details,
									audience_details: response.data.audience_details,
									user_role: response.data.user_role,
									audience_role: response.data.audience_role,
									question_list: response.data.question_list
								}
							},
							patient: {
								apiPath: "/api/patient/GetFirstMessage",
								dataUpdates: {
									user_role: response.data.user_role,
									visit_type: response.data.setup_name,
									patient_summary: response.data.patient_summary,
									patient_bio: response.data.patient_bio,
									patient_medical: response.data.patient_medical,
									question_list: response.data.question_list
								}
							},
							pitch: {
								apiPath: "/api/pitch/GetFirstMessage",
								dataUpdates: {
									target_time: response.data.target_time,
									pitch_topic: response.data.setup_name,
									pitch_details: response.data.setup_input,
									audience_role: response.data.audience_role,
									user_role: response.data.user_role
								}
							},
							business_pitch: {
								apiPath: "/api/business_pitch/GetFirstMessage",
								dataUpdates: {
									target_time: response.data.target_time,
									pitch_topic: response.data.setup_name,
									pitch_details: response.data.setup_input,
									audience_role: response.data.audience_role,
									user_role: response.data.user_role,
									question_list: response.data.question_list
								}
							},
							reflection: {
								apiPath: "/api/reflection/GetFirstMessage",
								dataUpdates: {
									experience_type: response.data.setup_name,
									experience_details: response.data.setup_input,
									experience_type_description: response.data.experience_type_description,
									phase: response.data.phase,
									competency_list: response.data.competency_list
								}
							}
						};

						if (setupTypeConfig[response.data.setup_type]) {
							const config = setupTypeConfig[response.data.setup_type];
							apiPath = config.apiPath;
							Object.assign(data, config.dataUpdates);
							Object.assign(setupDataUpdate, config.dataUpdates);
						}

						Object.assign(setupDataUpdate, commonUpdates);
						setSetupData((prevSetupData) => ({
							...prevSetupData,
							...setupDataUpdate,

							setup_type: response.data.setup_type,
							simulation_id: savedId,
							audience: !response.data.audience || response.data.audience[0] === "Random" ? ["Random"] : commonData.audience,
							personality: !response.data.audience || response.data.audience[0] === "Random" ? "???" : commonData.personality,
							creator_instage_id: commonData.creator_instage_id
						}));

						// If the saved sim is not a dynamic sim and has not been generated from a dynamic sim assignment, then load the canvas and first message
						if (!(response.data.assignment_type === "dynamic" && !response.data.assignment_simulation_id)) {
							setGenerateStarted(true);

							if (!simId) {
								axiosLimitedPost(url + apiPath, data, 1, {}, 30000)
									.then((resp3) => {
										setFirstMessageName(resp3.data.content.split(": ")[0].trim());
										setFirstMessageText(resp3.data.content.split(":").slice(1).join(" "));
										setShowStartSimBtn(true);
									})
									.catch((err) => {
										console.error(err);
									});
							}
						} else {
							setGenerateStarted(false);
						}
					} else if (response.data.simulation_id) {
						setSimDeleted(true);
					}
				})
				.catch((error) => {
					console.log("test test", error);
					if (error.status === 403) {
						setSimulationForbidden(true);
					} else if (error.status === 404) {
						setSimulationNotFound(true);
					}
				});

			let resume_user;
			// Search for any user_id query parameters in the URL
			const queryParams = new URLSearchParams(window.location.search);
			const user_id = queryParams.get("user_id");
			if (user_id) {
				resume_user = user_id;
			} else {
				// If no user_id query parameter is found, use the current user's ID
				resume_user = localUser.id;
			}

			axiosLimitedGet(`${url}/api/resume-assist/get`, 1, { params: { setup_id: savedId, instage_id: resume_user } }).then((response) => {
				// console.log("Resume details:", response.data);
				if (response.data.resume_details) {
					setResumeData({
						...response.data.resume_details,
						summary: Array.isArray(response.data.resume_details.summary)
							? response.data.resume_details.summary[0]?.summary || ""
							: response.data.resume_details.summary || ""
					});
					setResumeTemplateData({
						...response.data.resume_details,
						summary: Array.isArray(response.data.resume_details.summary)
							? response.data.resume_details.summary[0]?.summary || ""
							: response.data.resume_details.summary || ""
					});
				}
				if (response.data.cover_letter_details) {
					setCoverLetterData(response.data.cover_letter_details);
					setCoverLetterTemplateData({
						...response.data.cover_letter_details,
						contact: {
							firstName: "",
							lastName: "",
							email: "",
							phone: "",
							address: "",
							company: "",
							company_address: "",
							hiring_manager_name: ""
						}
					});
				}
				if (response.data.gpt_assist_details) {
					setGptAssistData(response.data.gpt_assist_details);
				}
				if (response.data.gpt_assist_score) {
					setGptAssistScore(response.data.gpt_assist_score);
				}
			});
		} else if (simId) {
			setGenerateStarted(true);
		} else {
			setGenerateStarted(false);
			setIsGenerating(false);
			setResumeData(null);
			setCoverLetterData(null);
			setGptAssistData(null);
			setGptAssistScore(null);
		}
	}, [savedId, simId, localUser?.id]);

	// On page unload
	useEffect(() => {
		const unloadHandler = () => {
			setGenerateStarted(false);
			setShowStartSimBtn(false);
			setActiveSessionData(null);
			setActiveTab(0);
			setResumeData(null);
			setCoverLetterData(null);
			setGptAssistData(null);
			setGptAssistScore(null);
			setDefaultSetupData();
		};

		window.addEventListener("popstate", unloadHandler);
		return () => {
			window.removeEventListener("popstate", unloadHandler);
		};
	}, []);

	const changeAvatar = async ({ newAudience, newPersonality }) => {
		if (setupData.audience[0] === newAudience[0] && setupData.personality === newPersonality) {
			// No changes to the audience or personality
			// We must set the personality to the correct value manually because the personality is not translated back correctly in some cases
			let englishPersonality = newPersonality;
			if (newPersonality === "Effronté") {
				englishPersonality = "Cheeky";
			} else if (newPersonality === "Espiègle") {
				englishPersonality = "Playful";
			} else if (newPersonality === "En colère") {
				englishPersonality = "Angry";
			} else if (newPersonality === "Réservé") {
				englishPersonality = "Reserved";
			}
			// console.log("No personality change");

			setSetupData((prevSetupData) => ({
				...prevSetupData,
				audience: newAudience,
				personality: englishPersonality
			}));

			const data2 = {
				simulation_id: savedId,
				value: englishPersonality,
				field: "personality"
			};

			await axiosLimitedPatch(`${url}/api/saved/simulation`, data2, 1, {}, 30000);
			return;
		}
		// console.log(newPersonality);
		// const englishPersonality = await translateSingleText(newPersonality, "en");
		let englishPersonality = newPersonality;

		if (newPersonality === "Effronté") {
			englishPersonality = "Cheeky";
		} else if (newPersonality === "Espiègle") {
			englishPersonality = "Playful";
		} else if (newPersonality === "En colère") {
			englishPersonality = "Angry";
		} else if (newPersonality === "Réservé") {
			englishPersonality = "Reserved";
		}

		// console.log("EnglishPersonality: ", englishPersonality);

		setShowStartSimBtn(false);
		setSetupData((prevSetupData) => ({
			...prevSetupData,
			audience: newAudience,
			personality: englishPersonality
		}));

		// Update the database with the new audience and personality
		const data1 = {
			simulation_id: savedId,
			value: newAudience,
			field: "audience"
		};
		const data2 = {
			simulation_id: savedId,
			value: englishPersonality,
			field: "personality"
		};
		await axiosLimitedPatch(`${url}/api/saved/simulation`, data1, 1, {}, 30000);
		await axiosLimitedPatch(`${url}/api/saved/simulation`, data2, 1, {}, 30000);

		const strTime = formatTime();

		const key_points =
			setupData.question_list?.map((question) => ({
				short: question.type,
				question: question.text,
				feedback: "",
				status: ""
			})) || [];

		let apiPath = "";

		let currentAudience;
		let currentPersonality;

		if (newAudience[0] === "Random") {
			currentAudience = [getRandomArrayElement(audienceOptions.filter((option) => option !== "Random"))];
			currentPersonality = getRandomArrayElement(personalityOptions).value;
			setRandomAudience(currentAudience);
			setRandomPersonality(currentPersonality);
		} else {
			currentAudience = newAudience;
			currentPersonality = newPersonality;
		}

		const firstMessageData = {
			instage_id: localUser.id,
			simulation_id: savedId,
			setup_name: setupData.setup_name,
			current_time: strTime,
			audience: currentAudience,
			personality: currentPersonality,
			userLanguage: userLanguage
		};

		const setupTypeConfig = {
			interview: {
				apiPath: "/api/interview/GetFirstMessage",
				dataUpdates: {
					job_title: setupData.job_title,
					job_description: setupData.job_description,
					short_summary: setupData.short_summary,
					job_function: setupData.job_function,
					experience_level: setupData.experience_level,
					industry: setupData.industry,
					main_responsibilities: setupData.main_responsibilities,
					skills_required: setupData.skills_required,
					question_list: setupData.question_list,
					keywords: setupData.keywords,
					...(localUser.resume_details && { resume_details: localUser.resume_details }),
					monsterUrl: setupData.monsterUrl || ""
				}
			},
			discovery: {
				apiPath: "/api/discovery/GetFirstMessage",
				dataUpdates: {
					product: setupData.setup_name,
					meeting_details: setupData.meeting_details,
					call_notes: setupData.call_notes,
					buyer_title: setupData.buyer_title,
					backstory: setupData.prospect_company_description,
					company: setupData.sales_rep_company_name,
					company_description_short: setupData.sales_rep_company_description,
					prospect_company_name: setupData.prospect_company_name,
					prospect_company_description: setupData.prospect_company_description,
					sales_rep_company_name: setupData.sales_rep_company_name,
					sales_rep_company_description: setupData.sales_rep_company_description,
					key_points,
					learning_points: learning_points_array,
					process_points: [process_preliminaries, process_opening, ...process_BANT, process_closing]
				}
			},
			discovery_spin: {
				apiPath: "/api/discovery_spin/GetFirstMessage",
				dataUpdates: {
					product: setupData.setup_name,
					meeting_details: setupData.meeting_details,
					call_notes: setupData.call_notes,
					buyer_title: setupData.buyer_title,
					company: setupData.sales_rep_company_name,
					company_description_short: setupData.sales_rep_company_description,
					prospect_company_name: setupData.prospect_company_name,
					prospect_company_description: setupData.prospect_company_description,
					sales_rep_company_name: setupData.sales_rep_company_name,
					sales_rep_company_description: setupData.sales_rep_company_description,
					buyer_situation_1: setupData.buyer_situation_1,
					buyer_situation_2: setupData.buyer_situation_2
				}
			},
			closing: {
				apiPath: "/api/closing/GetFirstMessage",
				dataUpdates: {
					product: setupData.setup_name,
					meeting_details: setupData.meeting_details,
					company: setupData.sales_rep_company_name,
					company_description_short: setupData.sales_rep_company_description,
					prospect_company_name: setupData.prospect_company_name,
					prospect_company_description: setupData.prospect_company_description,
					sales_rep_company_name: setupData.sales_rep_company_name,
					sales_rep_company_description: setupData.sales_rep_company_description,
					buyer_title: setupData.buyer_title,
					buyer_need_1: setupData.buyer_need_1,
					buyer_need_2: setupData.buyer_need_2,
					buyer_objection_1: setupData.buyer_objection_1,
					buyer_objection_2: setupData.buyer_objection_2
				}
			},
			retail: {
				apiPath: "/api/retail/GetFirstMessage",
				dataUpdates: {
					product: setupData.setup_name,
					additional_details: setupData.additional_details,
					buyer_title: setupData.buyer_title,
					company: setupData.sales_rep_company_name,
					company_description_short: setupData.sales_rep_company_description,
					sales_rep_company_name: setupData.sales_rep_company_name,
					sales_rep_company_description: setupData.sales_rep_company_description,
					question_list: setupData.question_list
				}
			},
			presentation: {
				apiPath: "/api/presentation/GetFirstMessage",
				dataUpdates: {
					topic: setupData.setup_name,
					presentation_details: setupData.presentation_details,
					target_time: setupData.target_time,
					audience_role: setupData.audience_role,
					objective: setupData.objective,
					question_list: setupData.question_list
				}
			},
			intro: {
				apiPath: "/api/intro/GetFirstMessage",
				dataUpdates: {
					intro_event: setupData.setup_name,
					intro_details: setupData.intro_details,
					audience_role: setupData.audience_role,
					user_role: setupData.user_role,
					question_list: setupData.question_list
				}
			},
			freestyle: {
				apiPath: "/api/freestyle/GetFirstMessage",
				dataUpdates: {
					convo_type: setupData.setup_name,
					user_details: setupData.user_details,
					audience_details: setupData.audience_details,
					user_role: setupData.user_role,
					audience_role: setupData.audience_role,
					question_list: setupData.question_list
				}
			},
			patient: {
				apiPath: "/api/patient/GetFirstMessage",
				dataUpdates: {
					user_role: setupData.user_role,
					visit_type: setupData.setup_name,
					patient_summary: setupData.patient_summary,
					patient_bio: setupData.patient_bio,
					patient_medical: setupData.patient_medical,
					question_list: setupData.question_list
				}
			},
			pitch: {
				apiPath: "/api/pitch/GetFirstMessage",
				dataUpdates: {
					target_time: setupData.target_time,
					pitch_topic: setupData.setup_name,
					pitch_details: setupData.pitch_details,
					audience_role: setupData.audience_role,
					user_role: setupData.user_role
				}
			},
			business_pitch: {
				apiPath: "/api/business_pitch/GetFirstMessage",
				dataUpdates: {
					target_time: setupData.target_time,
					pitch_topic: setupData.setup_name,
					pitch_details: setupData.pitch_details,
					audience_role: setupData.audience_role,
					user_role: setupData.user_role,
					question_list: setupData.question_list
				}
			},
			reflection: {
				apiPath: "/api/reflection/GetFirstMessage",
				dataUpdates: {
					experience_type: setupData.experience_type,
					experience_type_description: setupData.experience_type_description,
					experience_details: setupData.experience_details,
					phase: setupData.phase,
					competency_list: setupData.competency_list
				}
			}
		};

		if (setupTypeConfig[setupData.setup_type]) {
			const config = setupTypeConfig[setupData.setup_type];
			apiPath = config.apiPath;
			Object.assign(firstMessageData, config.dataUpdates);
		}

		if (!simId) {
			axiosLimitedPost(url + apiPath, firstMessageData, 1, {}, 30000)
				.then((resp) => {
					setFirstMessageName(resp.data.content.split(": ")[0].trim());
					setFirstMessageText(resp.data.content.split(":").slice(1).join(" "));
					setShowStartSimBtn(true);
				})
				.catch((err) => {
					console.error(err);
				});
		}
	};

	const handlePersonalityChange = (audienceMember, personality) => {
		setPersonalities({ ...personalities, [audienceMember]: personality });
		changeAvatar({ newAudience: [audienceMember], newPersonality: personality });
	};

	async function getOngoingSim() {
		const res = await axiosLimitedGet(`${url}/api/sessionData/session/ongoing`, 1, { params: { instage_id: localUser.id } });
		// console.log("ongoing", res.data);
		return res.data;
	}

	const startSimHandler = async () => {
		// If the user does not have access to resume assist, the question list includes a resume question
		// and the user does not have a resume, show the resume modal
		if (setup_type === "interview" && !resumeAssistAccess) {
			const requiresResume = setupData.question_list?.some((q) => q.type === "Resume");
			if (localUser && !localUser.resume_details && requiresResume) {
				setShowResumeModal(true);
				return;
			}
		}

		const ongoing = await getOngoingSim();

		if (ongoing) {
			// setShowOngoingModal(true);
			await axiosLimitedPost(`${url}/api/sessionData/session/ongoing`, { session_id: ongoing.session_id, value: false }, 1);
			startSim();
		} else {
			startSim();
		}
	};

	/// Starts the simulation.
	async function startSim() {
		console.log("Starting simulation...");
		setShowStartSimBtn(false);

		// Reset the chat messages
		updateChatMessages([]);
		let createSessionResp;
		let newSimId;
		let job_question_list;

		try {
			const start_time = new Date().toISOString().replace("T", " ").replace("Z", "+00");
			const sessionData = {
				simulation_fk: savedId,
				instage_id: localUser.id,
				client_id: tenantId,
				start_time,
				duration: 0,
				assignment_details: assignmentDetails,
				audience: randomAudience || setupData.audience,
				personality: randomPersonality || setupData.personality,
				submitted: false,
				chat_history: JSON.stringify([{ role: "assistant", content: `${firstMessageName}: ${firstMessageText}` }]),
				...(location.pathname.includes("preview") ? { tempUser: true } : {})
			};
			// console.log("sessionData", sessionData);

			try {
				posthog?.capture("instage_start_sim", {
					captureOn,
					videoOn,
					setup_id: setup_type,
					audience: sessionData.audience,
					personality: sessionData.personality,
					is_assignment: assignmentData.assignment_type !== "",
					assignment_details: assignmentDetails
				});
			} catch (error) {
				console.error("Posthog error:", error);
			}

			switch (setup_type) {
				case "interview":
					console.log("Interview setup...", setupData);
					const response = await axiosLimitedGet(`${url}/api/saved/simulation`, 1, {
						params: { simulation_id: setupData.simulation_id }
					});
					job_question_list = response.data.job_question_list.some((question) => question.text === "")
						? await generateQuestions({ temp: true })
						: response.data.job_question_list;
					Object.assign(sessionData, {
						setup_name: response.data.job_title,
						setup_type: setupData.setup_type,
						simulation_fk: setupData.simulation_id,
						job_title: response.data.job_title,
						job_description: response.data.job_description,
						short_summary: response.data.short_summary,
						job_function: response.data.job_function,
						experience_level: response.data.experience_level,
						industry: response.data.industry,
						main_responsibilities: response.data.main_responsibilities,
						skills_required: response.data.skills_required,
						job_question_list
					});
					break;
				case "presentation":
					Object.assign(sessionData, {
						setup_name: setupData.setup_name,
						setup_type: setupData.setup_type,
						topic: setupData.setup_name,
						presentation_details: setupData.presentation_details,
						target_time: setupData.target_time,
						audience_role: setupData.audience_role,
						objective: setupData.objective,
						question_list: setupData.question_list
					});
					break;
				case "retail":
					Object.assign(sessionData, {
						setup_name: setupData.setup_name,
						setup_type: setupData.setup_type,
						additional_details: setupData.additional_details,
						company: setupData.company,
						company_description_short: setupData.company_description_short,
						product: setupData.product,
						buyer_title: setupData.buyer_title,
						question_list: setupData.question_list
					});
					break;
				case "intro":
					Object.assign(sessionData, {
						setup_name: setupData.setup_name,
						setup_type: setupData.setup_type,
						intro_event: setupData.intro_event,
						intro_details: setupData.intro_details,
						audience_role: setupData.audience_role,
						user_role: setupData.user_role,
						question_list: setupData.question_list
					});
					break;
				case "freestyle":
					Object.assign(sessionData, {
						setup_name: setupData.setup_name,
						setup_type: setupData.setup_type,
						convo_type: setupData.convo_type,
						user_role: setupData.user_role,
						audience_role: setupData.audience_role,
						user_details: setupData.user_details,
						audience_details: setupData.audience_details,
						question_list: setupData.question_list
					});
					break;
				case "patient":
					Object.assign(sessionData, {
						setup_name: setupData.setup_name,
						setup_type: setupData.setup_type,
						visit_type: setupData.visit_type,
						patient_summary: setupData.patient_summary,
						patient_bio: setupData.patient_bio,
						patient_medical: setupData.patient_medical,
						user_role: setupData.user_role,
						question_list: setupData.question_list
					});
					break;
				case "closing":
					Object.assign(sessionData, {
						setup_name: setupData.setup_name,
						setup_type: setupData.setup_type,
						backstory,
						company: setupData.company,
						company_description_short: setupData.company_description_short,
						product: setupData.product,
						buyer_title: setupData.buyer_title,
						process_points: setupData.process_points ? JSON.stringify(setupData.process_points) : null,
						prospect_company_description: setupData.prospect_company_description,
						buyer_need_1: setupData.buyer_need_1,
						buyer_need_2: setupData.buyer_need_2,
						buyer_objection_1: setupData.buyer_objection_1,
						buyer_objection_2: setupData.buyer_objection_2
					});
					break;
				case "discovery":
				case "discovery_spin":
					Object.assign(sessionData, {
						setup_name: setupData.setup_name,
						setup_type: setupData.setup_type,
						backstory,
						company: setupData.company,
						company_description_short: setupData.company_description_short,
						product: setupData.product,
						product_list: JSON.stringify(setupData.product_list),
						budget: budget.toString(),
						call_notes: callNotes,
						buyer_title: setupData.buyer_title,
						process_points: setupData.process_points ? JSON.stringify(setupData.process_points) : null,
						key_points: JSON.stringify(setupData.key_points),
						talking_points: JSON.stringify(setupData.talking_points),
						learning_points: JSON.stringify(setupData.learning_points),
						prospect_company_description: setupData.prospect_company_description,
						buyer_situation_1: setupData.buyer_situation_1,
						buyer_situation_2: setupData.buyer_situation_2
					});
					break;
				case "pitch":
					Object.assign(sessionData, {
						setup_name: setupData.setup_name,
						setup_type: setupData.setup_type,
						pitch_topic: setupData.pitch_topic,
						pitch_details: setupData.pitch_details,
						audience_role: setupData.audience_role,
						user_role: setupData.user_role
					});
					break;
				case "business_pitch":
					Object.assign(sessionData, {
						setup_name: setupData.setup_name,
						setup_type: setupData.setup_type,
						pitch_topic: setupData.pitch_topic,
						pitch_details: setupData.pitch_details,
						audience_role: setupData.audience_role,
						user_role: setupData.user_role,
						question_list: setupData.question_list
					});
					break;
				case "reflection":
					Object.assign(sessionData, {
						setup_name: setupData.setup_name,
						setup_type: setupData.setup_type,
						experience_type: setupData.experience_type,
						experience_type_description: setupData.experience_type_description,
						experience_details: setupData.experience_details,
						phase: setupData.phase,
						competency_list: setupData.competency_list
					});
					break;
				default:
					break;
			}

			createSessionResp = await axiosLimitedPost(`${url}/api/sessionData/session`, sessionData, 1);
			console.log("Session Created: ", createSessionResp.data);
			setActiveSessionData(createSessionResp.data);
			newSimId = createSessionResp.data.session_id;

			try {
				const db = await initDB();
				await db.put("simulation_db", videoOn, "videoAccess");
				await db.put("simulation_db", saveAudio, "saveAudioAccess");
				await db.put("simulation_db", captureOn, "saveVideoAccess");
			} catch (e) {
				console.error(e);
			}
			const paramsString = searchParams.toString();

			if (location.pathname.includes("preview") && setup_type === "intro") {
				handleNavigate(
					`/preview-simulation/${setup_type}/${savedId}/${newSimId}/preview-simulation/${setup_type}/${savedId}/${newSimId}`,
					true
				);
			} else if (location.pathname.includes("guest-setup") && setup_type === "interview") {
				const clientId = window.location.pathname.split("/")[2];
				handleNavigate(`/guest-simulation/${clientId}/${savedId}/${newSimId}`, true);
			} else {
				handleNavigate(`/simulation/${setup_type}/${savedId}/${newSimId}`, true);
			}
			// navigate("/simulation" + `/${setup_id}` + `/${savedId}` + `/${newSimId}`);
		} catch (e) {
			setShowStartSimBtn(true);
			printChatError();
			console.log(e);
			console.error(e);
			throw e;
		}
	}

	function printChatError(message) {
		setChatMessages((prevChatMessages) => [
			...prevChatMessages,
			{ role: "transcriptError", content: "Uh oh! It looks like our servers are too busy right now. Please try again later" }
		]);
	}

	async function generateQuestions({ temp = false }) {
		setIsGenerating(true);

		const commonData = {
			instage_id: localUser.id,
			audience: randomAudience || setupData.audience,
			personality: randomPersonality || setupData.personality
		};

		let data = { ...commonData, ...setupData };

		if (setupData.setup_type.includes("interview")) {
			data = {
				...data,
				...(resumeAssistAccess && resumeData
					? { resume_data: resumeData }
					: localUser?.resume_details && { resume_details: localUser.resume_details })
			};
		}

		const resp = await axiosLimitedPost(`${url}/api/${setupData.setup_type}/GenerateQuestionList`, data, 1, {}, 50000);

		if (!temp) {
			setSetupData((prevSetupData) => ({
				...prevSetupData,
				question_list: resp.data.generated_list
			}));
		}

		setIsGenerating(false);
		return resp.data.generated_list;
	}

	const resetQuestions = () => {
		// Reset the text value of each question in setupData.question_list and presentationData.question_list to empty string
		const newQuestionList = setupData.question_list.map((question) => ({
			...question,
			text: ""
		}));

		setSetupData((prevSetupData) => ({
			...prevSetupData,
			question_list: newQuestionList
		}));
	};

	function editSimDetails() {
		const setup = memoizedSetupList.find((setup) => setup.setup_type === setup_type);
		setSetupData({ ...setup, ...defaultSetupData });
		setGenerateStarted(false);
		setShowStartSimBtn(false);
	}

	const { generateSim } = handleGeneration({
		localUser,
		assignmentData,
		setup_type,
		setupData,
		url,
		savedId,
		navigate,
		setGenerateStarted,
		setSetupData,
		getRandomArrayElement,
		audienceOptions,
		personalityOptions,
		personalities,
		setShowStartSimBtn,
		setCallNotes,
		setBackstory,
		setShowBackstory,
		budget,
		setImpressions,
		printChatError,
		setFirstMessageName,
		setFirstMessageText,
		setError1,
		setError2,
		setError3,
		setShowSidebar
	});

	const cancelPreviousSimAndStartNew = async () => {
		setShowOngoingModal(false);

		if (ongoingSim) {
			await axiosLimitedPost(`${url}/api/sessionData/session/ongoing`, { session_id: ongoingSim.session_id, value: false }, 1);
			setOngoingSim(null); // Clear the stored ongoing simulation
		}
		startSim();
	};

	function updateEduData(data) {
		// console.log("updateEduData", data, areAllKeysDefined(data));
		setAssignmentDetails(data);
	}
	function areAllKeysDefined(obj) {
		return Object.keys(obj).every((key) => obj[key] !== undefined);
	}
	useEffect(() => {
		let tempEditAccess = false;
		let tempQuestionListViewAccess = false;
		if (adminAccess) {
			tempQuestionListViewAccess = true;
			// check if we want other admins to have edit access
			tempEditAccess = assignmentData.creator_instage_id === localUser.id || assignmentData.creator_instage_id === undefined;
		} else {
			tempEditAccess = assignmentData.creator_instage_id === undefined; // Allows user to add questions, but not text
			tempQuestionListViewAccess = assignmentData.creator_instage_id === undefined;
		}
		setEditAccess(tempEditAccess);
		setQuestionListViewAccess(tempQuestionListViewAccess);
	}, [localUser, assignmentData]);

	useEffect(() => {
		async function fetchPersonalityTypes() {
			try {
				const personalityLabels = personalityOptions.map((option) => option.label);

				const translatedTypes = await translateDictText(personalityLabels, userLanguage);
				const translatedPersonalityOptions = personalityOptions.map((option, index) => ({
					...option,
					label: translatedTypes[index]
				}));
				setPersonalityOptions(translatedPersonalityOptions);
				// console.log("translatedPersonalityOptions: ", personalityOptions);
			} catch (error) {
				console.error("Error translating personality options:", error);
			}
		}

		if (personalityOptions.length > 0) {
			fetchPersonalityTypes();
		}
	}, [userLanguage, setupData, personalityOptions.length]);

	return (
		<>
			<Helmet>
				<title>InStage | Setup</title>
				<meta name="description" content="InStage Setup" />
				<meta name="robots" content="noindex" />
			</Helmet>
			{/* <MobileModal /> */}
			<BrowserSupportModal />
			<ResumeModal show={showResumeModal} onHide={() => setShowResumeModal(false)} showExplainer />

			{showAssignmentModal && (
				<AssignmentModal
					editAccess={editAccess}
					updateEduData={updateEduData}
					navigate={navigate}
					localUser={localUser}
					data={assignmentData}
					setData={setAssignmentData}
					setup_type={setup_type}
					setShowAssignmentModal={setShowAssignmentModal}
					newAssignmentType={newAssignmentType}
					setCaptureOn={setCaptureOn}
				/>
			)}

			{showOngoingModal && (
				<OngoingModal
					setShowOngoingModal={setShowOngoingModal}
					handleModalCancel={() => {
						setShowOngoingModal(false);
					}}
					handleModalConfirm={cancelPreviousSimAndStartNew}
				/>
			)}
			<div className={s.startPage} style={getLmsAssignmentType() ? { top: "unset", height: "100vh" } : {}}>
				{!simDeleted && setupData && (
					<SetupPage
						summitDeeplink={summitDeeplink}
						editAccess={editAccess}
						questionListViewAccess={questionListViewAccess}
						simulationForbidden={simulationForbidden}
						simulationNotFound={simulationNotFound}
						allowedToStart={clientType === "edu" ? areAllKeysDefined(assignmentDetails) : true}
						localUser={localUser}
						adminAccess={adminAccess}
						typingRefs1={typingRefs1}
						audienceOptions={audienceOptions}
						personalities={personalities}
						personalityOptions={personalityOptions}
						setPersonalityOptions={setPersonalityOptions}
						assignmentData={assignmentData}
						setAssignmentData={setAssignmentData}
						setShowAssignmentModal={setShowAssignmentModal}
						setNewAssignmentType={setNewAssignmentType}
						generateSim={generateSim}
						generateStarted={generateStarted}
						simDeleted={simDeleted}
						error1={error1}
						setError1={setError1}
						error2={error2}
						setError2={setError2}
						error3={error3}
						setError3={setError3}
						questionTypeOptions={questionTypeOptions}
						generateQuestions={generateQuestions}
						resetQuestions={resetQuestions}
						isGenerating={isGenerating}
						editSimDetails={editSimDetails}
						showStartSimBtn={showStartSimBtn}
						videoOn={videoOn}
						setVideoOn={setVideoOn}
						saveAudio={saveAudio}
						setSaveAudio={setSaveAudio}
						captureOn={captureOn}
						setCaptureOn={setCaptureOn}
						startSimHandler={startSimHandler}
						handlePersonalityChange={handlePersonalityChange}
						changeAvatar={changeAvatar}
						activeTab={activeTab}
						setActiveTab={setActiveTab}
						clientType={clientType}
					/>
				)}

				{simDeleted && (
					<>
						<h1 className={s.dashboardHeading}>Simulation Deleted</h1>
					</>
				)}
			</div>
		</>
	);
}
